<i18n>
{
  "en": {
    "delete-title": "Delete automation",
    "title-update": "Edit Automation",
    "title-create": "Automation",
    "section-title-1": "Automation",
    "section-title-2": "Conditions",
    "section-title-3": "Settings",
    "not-found": "Unable to edit the automation",
    "not-found-description": "We noticed you haven't created a workflow yet. To edit an automation you'll need to create one. Shall we start?",
    "not-found-action": "Click here to create",
    "input-1": "Name",
    "input-2": "Entity",
    "input-3": "Description",
    "input-4": "Execute when",
    "input-5": "Action to be triggered",
    "input-6": "If field",
    "input-8": "E-mail sender",
    "input-9": "Send to",
    "input-10": "E-mail receiver",
    "input-11": "E-mail title",
    "input-12": "E-mail message",
    "input-14": "If accepted, move to stage",
    "input-16": "If denied, move to stage",
    "input-17": "Phone number",
    "input-18": "Message",
    "input-19": "Field",
    "input-20": "Deal owner",
    "input-21": "Pipeline stage",
    "input-22": "New value",
    "input-43": "Formula",
    "input-23": "Tag name",
    "input-24": "Task type",
    "input-25": "Task owner",
    "input-26": "Completion time (hours)",
    "input-27": "Note",
    "input-28": "Method",
    "input-29": "Endpoint",
    "input-30": "Email for errors notification",
    "input-31": "Headers (JSON)",
    "input-32": "Body (JSON)",
    "input-33": "Description",
    "input-34": "Type",
    "input-35": "Method",
    "input-36": "Value",
    "input-37": "Status",
    "input-38": "Days to due date",
    "input-39": "Wepipe user",
    "input-40": "Token",
    "input-42": "Channel webhook",
    "input-41": "Channel ID",
    "placeholder-1": "Enter a name",
    "placeholder-2": "Select an entity",
    "placeholder-3": "Enter a description",
    "placeholder-4": "Select an action",
    "placeholder-5": "Select an action",
    "placeholder-8": "Enter an email",
    "placeholder-9": "Select an option",
    "placeholder-10": "Enter the emails",
    "placeholder-11": "Enter a title",
    "placeholder-14": "Select a stage",
    "placeholder-16": "Select a stage",
    "placeholder-17": "(99) 99999-9999",
    "placeholder-18": "Enter a message",
    "placeholder-19": "Select the field",
    "placeholder-20": "Select an owner",
    "placeholder-21": "Select a stage",
    "placeholder-22": "Enter a value",
    "placeholder-23": "Enter a name",
    "placeholder-24": "Select a type",
    "placeholder-25": "Select an owner",
    "placeholder-26": "Enter the number",
    "placeholder-27": "Enter a note",
    "placeholder-28": "Select the method",
    "placeholder-29": "Enter an endpoint",
    "placeholder-30": "Enter an email",
    "placeholder-33": "Enter a description",
    "placeholder-34": "Select a type",
    "placeholder-35": "Select a method",
    "placeholder-36": "Enter a value",
    "placeholder-37": "Select a status",
    "placeholder-38": "Enter a number of days",
    "placeholder-39": "Enter a token",
    "placeholder-40": "Enter the channel id",
    "placeholder-41": "Enter the webhook URL",
    "switch-label-1": "Automation enabled",
    "switch-label-2": "Define the email of the deal owner as sender",
    "hint": "Only 60 caracters permitted. No accentuation and no special caracters.",
    "remove-condition": "Remove condition",
    "add-condition": "Add condition",
    "save": "Save updates",
    "create": "Create automation",
    "true": "True",
    "false": "False",
    "operator": "Operator",
    "value": "Value",
    "frequency": "Frequency",
    "frequency-condition-1": "Hour to execute",
    "frequency-condition-2": "Weekday to execute",
    "frequency-condition-3": "Month day to execute",
    "group-input": "Group"
  },
  "pt-BR": {
    "delete-title": "Deletar automação",
    "title-update": "Editar Automação",
    "title-create": "Automação",
    "section-title-1": "Automação",
    "section-title-2": "Condições",
    "section-title-3": "Configurações",
    "not-found": "Não é possível editar a automação",
    "not-found-description": "Nós percebemos aqui que você não criou nenhum pipeline ainda. Para criar uma automação você vai precisar criar um pipeline primeiro. Que tal criar um agora?",
    "not-found-action": "Clique aqui para criar",
    "input-1": "Nome",
    "input-2": "Entidade",
    "input-3": "Descrição",
    "input-4": "Executar quando",
    "input-5": "Ação a ser disparada",
    "input-6": "Se o campo",
    "input-8": "E-mail do remetente",
    "input-9": "Enviar para",
    "input-10": "E-mail dos destinatários",
    "input-11": "Título do e-mail",
    "input-12": "Mensagem do e-mail",
    "input-14": "Se aceito, mover para o estágio",
    "input-16": "Se recusado, mover para o estágio",
    "input-17": "Número do telefone",
    "input-18": "Mensagem",
    "input-19": "Campo",
    "input-20": "Responsável do card",
    "input-21": "Estágio do pipeline",
    "input-22": "Novo valor",
    "input-43": "Fórmula",
    "input-23": "Nome da etiqueta",
    "input-24": "Tipo de tarefa",
    "input-25": "Responsável da tarefa",
    "input-26": "Tempo de conclusão (em horas)",
    "input-27": "Nota",
    "input-28": "Method",
    "input-29": "Endpoint",
    "input-30": "Email para a notificação de erros",
    "input-31": "Headers (JSON)",
    "input-32": "Body (JSON)",
    "input-33": "Descrição",
    "input-34": "Tipo",
    "input-35": "Método",
    "input-36": "Valor",
    "input-37": "Status",
    "input-38": "Dias para a data de vencimento",
    "input-39": "Usuário da wepipe",
    "input-40": "Token",
    "input-42": "Webhook do canal",
    "input-41": "ID do Canal",
    "placeholder-1": "Digite um nome",
    "placeholder-2": "Escolha a entidade",
    "placeholder-3": "Digite uma descrição",
    "placeholder-4": "Selecione uma ação",
    "placeholder-5": "Selecione uma ação",
    "placeholder-8": "Digite um email",
    "placeholder-9": "Selecione uma opção",
    "placeholder-10": "Digite os e-mails",
    "placeholder-11": "Digite um título",
    "placeholder-14": "Selecione o estágio",
    "placeholder-16": "Selecione o estágio",
    "placeholder-17": "(99) 99999-9999",
    "placeholder-18": "Digite uma mensagem",
    "placeholder-19": "Selecione o campo",
    "placeholder-20": "Selecione um responsável",
    "placeholder-21": "Selecione o estágio",
    "placeholder-22": "Digite um valor",
    "placeholder-23": "Digite um nome",
    "placeholder-24": "Selecione um tipo",
    "placeholder-25": "Selecione um responsável",
    "placeholder-26": "Digite o número",
    "placeholder-27": "Digite uma nota",
    "placeholder-28": "Selecione o método",
    "placeholder-29": "Digite um endpoint",
    "placeholder-30": "Digite um email",
    "placeholder-33": "Digite uma descrição",
    "placeholder-34": "Selecione um tipo",
    "placeholder-35": "Selecione um método",
    "placeholder-36": "Digite um valor",
    "placeholder-37": "Selecione um status",
    "placeholder-38": "Digite a quantidade de dias",
    "placeholder-39": "Digite o token",
    "placeholder-40": "Digite o id do canal",
    "placeholder-41": "Digite a URL do webhook",
    "switch-label-1": "Automação ativada",
    "switch-label-2": "Definir e-mail do responsável do card como remetente",
    "hint": "Só é permitido 60 caracteres, sem acentuação e sem caracteres especiais.",
    "remove-condition": "Remover condição",
    "add-condition": "Adicionar condição",
    "save": "Salvar alterações",
    "create": "Criar automação",
    "true": "Verdadeiro",
    "false": "Falso",
    "operator": "Sinal",
    "value": "Valor",
    "frequency": "Frequência",
    "frequency-condition-1": "Hora para executar",
    "frequency-condition-2": "Dia da semana para executar",
    "frequency-condition-3": "Dia do mês para executar",
    "group-input": "Grupo"
  }
}
</i18n>

<template>
  <div id="edit-workflow">
    <nav-top
      returnPage="workflows"
      :title="workflowExist ? $t('title-update') : $t('title-create')"
    >
      <template #top-content>
        <we-button
          class="success"
          :text="workflowExist ? $t('save') : $t('create')"
          :icon="workflowExist ? 'check' : 'plus'"
          @click="workflowAction"
          :loading="btnLoading"
        />
        <confirm-modal
          :open="confirmDeleteOpened"
          @close="confirmDeleteOpened = false"
          @confirm="deleteWorkflow"
        />
        <we-icon-button
          :icon="['far', 'trash-alt']"
          :name="$t('delete-title')"
          direction="bottom"
          @click="confirmDeleteOpened = true"
        />
      </template>
    </nav-top>
    <board-form>
      <template #form-content>
        <router-view />
        <div
          class="content"
          v-if="!loading && pipelines && pipelines.length > 0"
        >
          <v-form ref="form" class="body" @submit.prevent>
            <section class="section">
              <div class="section-name">
                <font-awesome-icon icon="wand-magic-sparkles" />
                {{ $t("section-title-1") }}
              </div>
              <div class="section-fields">
                <v-switch
                  v-model="workflow.is_active"
                  :label="$t('switch-label-1')"
                  hide-details
                  class="double-column"
                />
                <we-input
                  type="text"
                  class="double-column"
                  :label="$t('input-1')"
                  :placeholder="$t('placeholder-1')"
                  v-model="workflow.name"
                  is-required
                />
                <we-input
                  class="double-column"
                  type="textarea"
                  :label="$t('input-3')"
                  :placeholder="$t('placeholder-3')"
                  v-model="workflow.description"
                  is-required
                />
                <we-input
                  type="tag"
                  v-model="workflow.group"
                  :items="groupsList"
                  :clearable="false"
                  :label="$t('group-input')"
                  :placeholder="$t('group-input')"
                />
                <we-input
                  type="global-select"
                  :label="$t('input-2')"
                  :placeholder="$t('placeholder-2')"
                  :clearable="false"
                  :items="entityList"
                  @input="changeEntity"
                  v-model="workflow.entity"
                  is-required
                />
                <we-input
                  type="global-select"
                  :label="$t('input-4')"
                  :placeholder="$t('placeholder-4')"
                  :clearable="false"
                  :items="enabledExecuteWhenList"
                  v-model="workflow.execute_when"
                  is-required
                />
                <we-input
                  type="global-select"
                  :label="$t('input-5')"
                  :placeholder="$t('placeholder-5')"
                  :clearable="false"
                  :items="enabledActionsList"
                  v-model="workflow.action"
                  @input="changeWorkflowAction"
                  is-required
                />
                <template v-if="['frequently'].includes(workflow.execute_when)">
                  <we-input
                    type="global-select"
                    :label="$t('frequency')"
                    :placeholder="$t('frequency')"
                    :clearable="false"
                    :items="frequencyList"
                    v-model="workflow.frequency"
                    @input="workflow.frequency_condition = 1"
                    is-required
                  />
                  <we-input
                    v-if="workflow.frequency && workflow.frequency === 'daily'"
                    type="select-search"
                    :label="$t('frequency-condition-1')"
                    :placeholder="$t('frequency-condition-1')"
                    :clearable="false"
                    :items="getHoursInADay()"
                    v-model="workflow.frequency_condition"
                    is-required
                  />
                  <we-input
                    v-if="workflow.frequency && workflow.frequency === 'weekly'"
                    type="global-select"
                    :label="$t('frequency-condition-2')"
                    :placeholder="$t('frequency-condition-2')"
                    :clearable="false"
                    :items="weekdaysList"
                    v-model="workflow.frequency_condition"
                    is-required
                  />
                  <we-input
                    v-if="
                      workflow.frequency && workflow.frequency === 'monthly'
                    "
                    type="select-search"
                    :label="$t('frequency-condition-3')"
                    :placeholder="$t('frequency-condition-3')"
                    :clearable="false"
                    :items="[...Array.from(Array(28).keys()).map(e => e + 1)]"
                    v-model="workflow.frequency_condition"
                    is-required
                  />
                </template>
              </div>
            </section>
            <section
              class="section"
              v-if="workflow.execute_when && workflow.execute_when.length > 0"
            >
              <div class="section-name">
                <font-awesome-icon icon="sliders-h" />
                {{ $t("section-title-2") }}
              </div>
              <div
                class="dynamic-fields fill"
                :class="{ 'one-condition': onlyOneCondition }"
                v-for="(condition, i) in workflowConditions"
                :key="condition.id"
              >
                <we-input
                  type="global-select"
                  v-model="condition.filterField"
                  :label="$t('input-6')"
                  @input="
                    changeConditionField(condition);
                    resetValueAndMask(condition);
                  "
                  :items="conditionFieldsList"
                  :clearable="false"
                />
                <we-input
                  :label="$t('operator')"
                  v-model="condition.operator"
                  type="global-select"
                  :items="operatorItems"
                  :clearable="false"
                  @input="resetValueAndMask(condition)"
                  hide-details
                />
                <we-input-user
                  v-if="condition.valueField.type === 'users'"
                  :label="$t('value')"
                  :value="condition.valueField.value"
                  @input="condition.valueField.value = $event.id"
                />
                <we-input-products
                  v-else-if="condition.valueField.type === 'products'"
                  :label="$t('value')"
                  v-model="condition.valueField.value"
                />
                <we-input-contacts
                  v-else-if="condition.valueField.type === 'contacts'"
                  :label="$t('value')"
                  v-model="condition.valueField.value"
                />
                <we-input-companies
                  v-else-if="condition.valueField.type === 'companies'"
                  :label="$t('value')"
                  v-model="condition.valueField.value"
                />
                <we-input
                  v-else
                  :type="condition.valueField.type"
                  v-model="condition.valueField.value"
                  v-maska="condition.valueField.mask"
                  :items="condition.valueField.items"
                  :clearable="false"
                  :label="$t('value')"
                  hide-details
                />
                <we-icon-button
                  v-if="!onlyOneCondition"
                  :icon="{ prefix: 'far', iconName: 'trash-alt' }"
                  :name="$t('remove-condition')"
                  @click="removeWorkflowCondition(i)"
                />
              </div>
              <we-button
                v-if="!onlyOneCondition"
                class="disabled centered"
                :text="$t('add-condition')"
                icon="plus"
                @click="addCondition"
              />
            </section>
            <section
              class="section"
              v-if="
                workflow.action.length > 0 &&
                  !['duplicate', 'remove_tags'].includes(workflow.action)
              "
            >
              <div class="section-name">
                <font-awesome-icon icon="cog" />
                {{ $t("section-title-3") }}
              </div>
              <div class="section-fields">
                <v-switch
                  v-if="
                    ['send_email', 'send_confirmation_email'].includes(
                      workflow.action
                    ) && ['deal'].includes(workflow.entity)
                  "
                  class="double-column"
                  :label="$t('switch-label-2')"
                  v-model="senderIsDealResponsible"
                  hide-details
                  is-required
                />
                <we-input
                  v-if="
                    ['send_email', 'send_confirmation_email'].includes(
                      workflow.action
                    ) && !senderIsDealResponsible
                  "
                  :class="{ fill: hideRelatedTo }"
                  type="email"
                  :label="$t('input-8')"
                  :placeholder="$t('placeholder-8')"
                  v-model="workflow.settings.from"
                  is-required
                />
                <we-input
                  v-if="['send_whatsapp'].includes(workflow.action)"
                  type="text"
                  v-model="workflow.settings.token"
                  class="single-column"
                  :label="$t('input-40')"
                  :placeholder="$t('placeholder-39')"
                  is-required
                />
                <we-input
                  v-if="
                    !hideRelatedTo &&
                      [
                        'send_email',
                        'send_confirmation_email',
                        'send_sms',
                        'send_whatsapp'
                      ].includes(workflow.action)
                  "
                  @input="workflow.settings.to = ''"
                  :label="$t('input-9')"
                  :placeholder="$t('placeholder-9')"
                  type="select"
                  :items="relatedToList"
                  v-model="relatedTo"
                  is-required
                />
                <we-input
                  v-if="
                    ['send_email', 'send_confirmation_email'].includes(
                      workflow.action
                    ) && relatedTo === 'others'
                  "
                  class="double-column"
                  type="tags"
                  :label="$t('input-10')"
                  :placeholder="$t('placeholder-10')"
                  v-model="receiverEmails"
                  :items="receiverEmails"
                  is-required
                />
                <we-input
                  v-if="
                    ['send_email', 'send_confirmation_email'].includes(
                      workflow.action
                    )
                  "
                  class="double-column"
                  type="text"
                  :label="$t('input-11')"
                  :placeholder="$t('placeholder-11')"
                  v-model="workflow.settings.subject"
                  is-required
                />
                <we-editor
                  v-if="
                    ['send_email', 'send_confirmation_email'].includes(
                      workflow.action
                    )
                  "
                  :label="$t('input-12')"
                  v-model="workflow.settings.message"
                  class="double-column"
                  enable-html
                />
                <we-input
                  v-if="['send_confirmation_email'].includes(workflow.action)"
                  type="global-select"
                  :value="selectedStageId"
                  @input="selectEmailAcceptedStage"
                  :items="allStagesWithPipelinesList"
                  :label="$t('input-14')"
                  :placeholder="$t('placeholder-14')"
                  is-required
                />
                <we-input
                  v-if="['send_confirmation_email'].includes(workflow.action)"
                  type="global-select"
                  :value="selectedDeclinedStageId"
                  @input="selectEmailDeclinedStage"
                  :items="allStagesWithPipelinesList"
                  :label="$t('input-16')"
                  :placeholder="$t('placeholder-16')"
                  is-required
                />
                <we-input
                  v-if="
                    ['send_sms', 'send_whatsapp'].includes(workflow.action) &&
                      relatedTo === 'others'
                  "
                  type="text"
                  :class="{ fill: hideRelatedTo }"
                  :label="$t('input-17')"
                  :placeholder="$t('placeholder-17')"
                  :mask="$root.$t('phone-mask')"
                  v-model="workflow.settings.to"
                  is-required
                />
                <we-input
                  v-if="['send_slack'].includes(workflow.action)"
                  type="text"
                  v-model="workflow.settings.token"
                  class="single-column"
                  :label="$t('input-40')"
                  :placeholder="$t('placeholder-39')"
                  is-required
                />
                <we-input
                  v-if="['send_slack'].includes(workflow.action)"
                  type="text"
                  v-model="workflow.settings.channel_id"
                  class="single-column"
                  :label="$t('input-41')"
                  :placeholder="$t('placeholder-40')"
                  is-required
                />
                <we-input
                  v-if="['send_discord'].includes(workflow.action)"
                  type="text"
                  class="double-column"
                  v-model="workflow.settings.webhook"
                  :label="$t('input-42')"
                  :placeholder="$t('placeholder-41')"
                  is-required
                />
                <we-input
                  v-if="['send_sms'].includes(workflow.action)"
                  type="text"
                  v-model="workflow.settings.message"
                  class="double-column"
                  :label="$t('input-18')"
                  :placeholder="$t('placeholder-18')"
                  counter="160"
                  maxlength="160"
                  :hint="$t('hint')"
                  is-required
                />
                <we-editor
                  v-if="
                    ['send_whatsapp', 'send_discord', 'send_slack'].includes(
                      workflow.action
                    )
                  "
                  :label="$t('input-18')"
                  v-model="workflow.settings.message"
                  class="double-column"
                />
                <we-input
                  v-if="['calculate_field_value'].includes(workflow.action)"
                  type="global-select"
                  v-model="workflow.settings.field"
                  :items="intCustomFieldsList"
                  :label="$t('input-19')"
                  :placeholder="$t('placeholder-19')"
                  is-required
                />
                <we-input
                  v-if="['calculate_field_value'].includes(workflow.action)"
                  type="text"
                  :label="$t('input-43')"
                  v-model="workflow.settings.formula"
                  class="single-column"
                />
                <we-sms-tax
                  v-if="['send_sms'].includes(workflow.action)"
                  class="double-column"
                />
                <template
                  v-if="['change_field_value'].includes(workflow.action)"
                >
                  <we-input
                    type="global-select"
                    v-model="workflow.settings.field"
                    :items="conditionFieldsList"
                    :label="$t('input-19')"
                    :placeholder="$t('placeholder-19')"
                    @input="
                      workflow.settings.new_value = null;
                      changeFieldValue.filterField = $event;
                      changeConditionField(changeFieldValue);
                      resetValueAndMask(changeFieldValue);
                    "
                    is-required
                  />
                  <we-input-user
                    v-if="['user_id'].includes(workflow.settings.field)"
                    :label="$t('input-20')"
                    :placeholder="$t('placeholder-20')"
                    :value="workflow.settings.new_value"
                    @input="workflow.settings.new_value = $event.id"
                  />
                  <we-input-products
                    v-else-if="['product_id'].includes(workflow.settings.field)"
                    :label="$t('value')"
                    :value="workflow.settings.new_value"
                    @input="workflow.settings.new_value = $event.id"
                  />
                  <we-input-contacts
                    v-else-if="['contact_id'].includes(workflow.settings.field)"
                    :label="$t('value')"
                    :value="workflow.settings.new_value"
                    @input="workflow.settings.new_value = $event.id"
                  />
                  <we-input-companies
                    v-else-if="['company_id'].includes(workflow.settings.field)"
                    :label="$t('value')"
                    :value="workflow.settings.new_value"
                    @input="workflow.settings.new_value = $event.id"
                  />
                  <we-input
                    v-else-if="
                      ['pipeline_stage_id'].includes(workflow.settings.field)
                    "
                    type="global-select"
                    v-model="workflow.settings.new_value"
                    :items="allStagesWithPipelinesList"
                    :label="$t('input-21')"
                    :placeholder="$t('placeholder-21')"
                    is-required
                  />
                  <we-input
                    v-else
                    :type="changeFieldValue.valueField.type"
                    v-model="workflow.settings.new_value"
                    v-maska="changeFieldValue.valueField.mask"
                    :items="changeFieldValue.valueField.items"
                    :clearable="false"
                    :label="$t('input-22')"
                    :placeholder="$t('placeholder-22')"
                    hide-details
                  />
                </template>
                <we-input
                  v-if="['add_tag', 'remove_tag'].includes(workflow.action)"
                  v-model="workflow.settings.tag"
                  type="text"
                  :label="$t('input-23')"
                  :placeholder="$t('placeholder-23')"
                  is-required
                />
                <we-input
                  v-if="['add_task'].includes(workflow.action)"
                  type="task"
                  :label="$t('input-24')"
                  :placeholder="$t('placeholder-24')"
                  :value="workflow.settings.task_type_id"
                  @input="workflow.settings.task_type_id = $event.id"
                  :items="tasksList"
                  is-required
                />
                <we-input-user
                  v-if="['add_task'].includes(workflow.action)"
                  :label="$t('input-25')"
                  :placeholder="$t('placeholder-25')"
                  :value="workflow.settings.responsible_user_id"
                  @input="workflow.settings.responsible_user_id = $event.id"
                />
                <we-input
                  v-if="['add_task'].includes(workflow.action)"
                  type="number"
                  :label="$t('input-26')"
                  :placeholder="$t('placeholder-26')"
                  v-model="workflow.settings.timeout"
                  is-required
                />
                <we-input
                  v-if="['add_task', 'add_note'].includes(workflow.action)"
                  type="textarea"
                  class="double-column"
                  :label="$t('input-27')"
                  :placeholder="$t('placeholder-27')"
                  v-model="workflow.settings.note"
                />
                <we-input
                  v-if="['webhook'].includes(workflow.action)"
                  type="global-select"
                  v-model="workflow.settings.method"
                  :items="webhookMethodsList"
                  :label="$t('input-28')"
                  :placeholder="$t('placeholder-28')"
                  is-required
                />
                <we-input
                  v-if="['webhook'].includes(workflow.action)"
                  type="text"
                  v-model="workflow.settings.endpoint"
                  :label="$t('input-29')"
                  :placeholder="$t('placeholder-29')"
                  is-required
                />
                <we-input
                  v-if="['webhook'].includes(workflow.action)"
                  type="email"
                  class="double-column"
                  v-model="workflow.settings.email"
                  :label="$t('input-30')"
                  :placeholder="$t('placeholder-30')"
                  is-required
                />
                <we-code-editor
                  v-if="['webhook'].includes(workflow.action)"
                  :label="$t('input-31')"
                  v-model="workflow.settings.headers"
                  class="double-column"
                />
                <we-code-editor
                  v-if="['webhook'].includes(workflow.action)"
                  :label="$t('input-32')"
                  v-model="workflow.settings.body"
                  class="double-column"
                />
                <we-input
                  v-if="['add_bill'].includes(workflow.action)"
                  type="text"
                  v-model="workflow.settings.description"
                  :label="$t('input-33')"
                  :placeholder="$t('placeholder-33')"
                  is-required
                />
                <we-input
                  v-if="['add_bill'].includes(workflow.action)"
                  v-model="workflow.settings.type"
                  type="global-select"
                  :label="$t('input-34')"
                  :placeholder="$t('placeholder-34')"
                  :items="billTypesList"
                  is-required
                />
                <we-input
                  v-if="['add_bill'].includes(workflow.action)"
                  v-model="workflow.settings.method"
                  type="global-select"
                  :label="$t('input-35')"
                  :placeholder="$t('placeholder-35')"
                  :items="billMethodsList"
                  is-required
                />
                <we-input
                  v-if="['add_bill'].includes(workflow.action)"
                  v-model="workflow.settings.total"
                  type="money"
                  :label="$t('input-36')"
                  :placeholder="$t('placeholder-36')"
                  is-required
                />
                <we-input
                  v-if="['add_bill'].includes(workflow.action)"
                  v-model="workflow.settings.status"
                  type="global-select"
                  :label="$t('input-37')"
                  :placeholder="$t('placeholder-37')"
                  :items="billStatusList"
                  is-required
                />
                <we-input
                  v-if="['add_bill'].includes(workflow.action)"
                  v-model="workflow.settings.timeout"
                  type="number"
                  :label="$t('input-38')"
                  :placeholder="$t('placeholder-38')"
                  is-required
                />
                <template
                  v-if="['send_notification'].includes(workflow.action)"
                >
                  <we-input
                    type="global-select"
                    v-model="notificationTo"
                    :items="enabledNotificationToList"
                    :label="$t('input-9')"
                    :placeholder="$t('placeholder-9')"
                    is-required
                  />
                  <we-input-user
                    v-if="notificationTo === 'other'"
                    :label="$t('input-39')"
                    :placeholder="$t('input-39')"
                    :value="workflow.settings.to"
                    @input="workflow.settings.to = $event.id"
                  />
                  <we-input
                    type="text"
                    v-model="workflow.settings.message"
                    class="double-column"
                    :label="$t('input-18')"
                    :placeholder="$t('placeholder-18')"
                    counter="120"
                    maxlength="120"
                    is-required
                  />
                </template>
              </div>
            </section>
          </v-form>
        </div>
        <we-loading-overflow :loading="loading" />
        <we-not-found v-if="!loading && (!pipelines || pipelines.length === 0)">
          <template #title>
            {{ $t("not-found") }}
          </template>
          <template #description>
            {{ $t("not-found-description") }}
            <router-link
              :to="{ name: 'pipelinesConfigs' }"
              class="primary--text"
              active-class="active"
            >
              {{ $t("not-found-action") }}
            </router-link>
          </template>
          <template #picture>
            <img
              src="@/assets/undraw/webform.svg"
              alt="Onboarding"
              width="299"
              height="254"
            />
          </template>
        </we-not-found>
      </template>
    </board-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      confirmDeleteOpened: false,
      senderIsDealResponsible: false,
      searchLoading: false,
      btnLoading: false,
      selectedPipelineId: 0,
      selectedStageId: 0,
      selectedDeclinedPipelineId: 0,
      selectedDeclinedStageId: 0,
      idNumber: 1,
      loading: true,
      relatedTo: "",
      notificationTo: null,
      hideRelatedTo: false,
      receiverEmails: [],
      loadingDelete: false,
      changeFieldValue: {
        id: 0,
        filterField: "",
        operator: "=",
        valueField: {
          value: "",
          type: "text",
          mask: ""
        }
      },
      workflow: {
        is_active: true,
        entity: this.$store.getters.getCreateWorkflowEntity || "deal",
        name: "",
        description: "",
        execute_when: "",
        action: "",
        frequency: "",
        frequency_condition: 0,
        conditions: null,
        settings: {
          new_value: 0,
          tag: "",
          task_type_id: 0,
          responsible_user_id: 0,
          timeout: 0,
          note: "",
          headers: `{
  "Content-Type": "application/json",
  "Authorization": "Bearer ..."
}`,
          body: `{
  "id": "{id}",
  "code": "{code}",
  "name": "{name}",
  "amount": "{amount}",
  "owner": "{owner}",
  "contact_first_name": "{contact_first_name}",
  "cf_nome_do_campo": "{cf_nome_do_campo}"
}`
        }
      }
    };
  },
  computed: {
    groups() {
      return this.$store?.getters?.getWorkflowGroups;
    },
    groupsList() {
      if (!this.workflow.group) return this.groups;

      return [this.workflow.group, ...this.groups];
    },
    taskTypesList() {
      return this.$store.getters.getTasks?.map(e => {
        return {
          text: e.name,
          value: e.id
        };
      });
    },
    weekdaysList() {
      return this.$store.getters.getWeekdaysList;
    },
    billMethodsList() {
      return this.$store.getters.getBillMethodsList;
    },
    billStatusList() {
      return this.$store.getters.getBillStatusList;
    },
    billTypesList() {
      return this.$store.getters.getBillTypesList;
    },
    onlyOneCondition() {
      return false;
    },
    tasksList() {
      return this.$store.getters.getTasks;
    },
    operatorItems() {
      return this.$store.getters.getOperatorItems;
    },
    workflowConditions() {
      return this.$store.getters.getWorkflowConditions;
    },
    relatedToList() {
      return this.$store.getters.getWorkflowRelatedToList.filter(
        e => !e.entitiesDisabled.includes(this.workflow.entity)
      );
    },
    entityList() {
      return this.$store.getters.getWorkflowEntityList;
    },
    executeWhenList() {
      return this.$store.getters.getWorkflowExecuteWhenList;
    },
    actionsList() {
      return this.$store.getters.getWorkflowActionsList;
    },
    frequencyList() {
      return this.$store.getters.getWorkflowFrequencyList;
    },
    webhookMethodsList() {
      return this.$store.getters.getWorkflowWebhookMethodsList;
    },
    notificationToList() {
      return this.$store.getters.getWorkflowNotificationToList;
    },
    enabledNotificationToList() {
      return this.notificationToList.filter(
        e => !e.entitiesDisabled.includes(this.workflow.entity)
      );
    },
    settingsFields() {
      return this.$store.getters.getSendEmailSettings;
    },
    workflows() {
      return this.$store.getters.getWorkflows;
    },
    customFields() {
      return this.$store.getters.getCustomFields;
    },
    enabledExecuteWhenList() {
      return this.executeWhenList.filter(
        e => !e.entitiesDisabled.includes(this.workflow.entity)
      );
    },
    enabledActionsList() {
      return this.actionsList.filter(
        e => !e.entitiesDisabled.includes(this.workflow.entity)
      );
    },
    customFieldsList() {
      return (
        this.customFields
          ?.filter(e => e.entity === this.workflow.entity)
          .map(e => {
            return {
              text: e.label,
              value: e.name
            };
          }) || []
      );
    },
    intCustomFieldsList() {
      return (
        this.customFields
          ?.filter(e => e.type == "number")
          .map(e => {
            return {
              text: e.label,
              value: e.name
            };
          }) || []
      );
    },
    conditionFieldsList() {
      let list = [];

      if (["deal"].includes(this.workflow.entity)) {
        list = this.$store.getters.getDealFields;
      } else if (["company"].includes(this.workflow.entity)) {
        list = this.$store.getters.getCompanyFields;
      } else if (["user"].includes(this.workflow.entity)) {
        list = this.$store.getters.getUserFields;
      } else if (["task"].includes(this.workflow.entity)) {
        list = this.$store.getters.getTaskFields;
      } else if (["contact"].includes(this.workflow.entity)) {
        list = this.$store.getters.getContactFields;
      } else if (["product"].includes(this.workflow.entity)) {
        list = this.$store.getters.getProductFields;
      } else if (["bill"].includes(this.workflow.entity)) {
        list = this.$store.getters.getBillFields;
      }

      if (this.workflow.execute_when === "stage_update") {
        list = list.filter(e => e.value !== "pipeline_id");
      }

      return [...list, ...this.customFieldsList];
    },
    indexList() {
      let list = [];
      for (let i = 1; i <= this.workflows?.length + 1; i++) {
        list.push(i);
      }
      return list;
    },
    pipelines() {
      return this.$store.getters.getPipelines;
    },
    pipelinesList() {
      let items = [];
      if (this.pipelines?.length > 0) {
        items = [...this.pipelines].map(e => {
          return {
            text: e.name,
            value: e.id
          };
        });
      }

      return items;
    },
    stagesList() {
      let items = [];

      if (this.pipelines?.length > 0) {
        const pipeline = [...this.pipelines].find(
          e => e.id === this.selectedPipelineId
        );
        items = pipeline?.stages.map(e => {
          return {
            text: e.name,
            value: e.id
          };
        });
      }

      return items;
    },
    allStagesWithPipelinesList() {
      let items = [];

      this.pipelines.forEach(pipeline => {
        pipeline?.stages.forEach(stage => {
          items.push({
            text: `${stage.name} (${pipeline.name})`,
            value: stage.id,
            pipelineId: pipeline.id
          });
        });
      });

      return items;
    },
    workflowExist() {
      return this.$route.params.id;
    }
  },
  methods: {
    ...mapActions([
      "editWorkflowRequest",
      "createWorkflowRequest",
      "pipelinesRequest",
      "customFieldsRequest",
      "workflowRequest",
      "tasksRequest",
      "userRequest",
      "deleteWorkflowRequest",
      "workflowGroupsRequest"
    ]),
    selectEmailAcceptedStage(evt) {
      this.selectedStageId = evt;

      const selectedStage = this.allStagesWithPipelinesList.find(
        e => e.value === evt
      );

      this.selectedPipelineId = selectedStage.pipelineId || 0;
    },
    selectEmailDeclinedStage(evt) {
      this.selectedDeclinedStageId = evt;

      const selectedStage = this.allStagesWithPipelinesList.find(
        e => e.value === evt
      );

      this.selectedDeclinedPipelineId = selectedStage.pipelineId || 0;
    },
    getHoursInADay() {
      return [...Array(24).keys()].map(e => `${e}`);
    },
    resetValueAndMask(condition) {
      condition.valueField.value = null;
      condition.valueField.mask = "";
    },
    isCheckbox(field) {
      return field.includes("is_");
    },
    changeWorkflowAction() {
      this.senderIsDealResponsible = false;
      this.workflow.settings.message = "";

      if (
        ["send_email", "send_confirmation_email"].includes(this.workflow.action)
      ) {
        this.workflow.settings.message = `
          <p>
            Olá
            <span
              data-type="mention"
              class="mention"
              data-id="contact_first_name"
              contenteditable="false"
              >{contact_first_name</span
            >
            ,
          </p>
          <p></p>
          <p>
            esse é um email disparado pela automação da wepipe. Para utilizar 
            valores de campos digite o símbolo chaves "{" e escolha a variável do 
            sistema que deseja enviar no email conforme os exemplos abaixo.
          </p>
          <p></p>
          <ul>
            <li>
              <p>
                Nome do card:
                <span
                  data-type="mention"
                  class="mention"
                  data-id="name"
                  contenteditable="false"
                  >{name</span
                >
              </p>
            </li>
            <li>
              <p>
                Código do card:
                <span
                  data-type="mention"
                  class="mention"
                  data-id="code"
                  contenteditable="false"
                  >{code</span
                >
              </p>
            </li>
            <li>
              <p>
                Nome do pipeline:
                <span
                  data-type="mention"
                  class="mention"
                  data-id="pipeline_name"
                  contenteditable="false"
                  >{pipeline_name</span
                >
              </p>
            </li>
          </ul>`;
      }

      if (
        ["send_whatsapp", "send_discord", "send_slack"].includes(
          this.workflow.action
        )
      ) {
        this.workflow.settings.message = `
          <p>
            Para utilizar valores de campos digite o símbolo chaves "{" e
            escolha a variável do  sistema que deseja enviar na mensagem.
          </p>
        `;
      }
    },
    async deleteWorkflow() {
      this.loadingDelete = true;
      await this.deleteWorkflowRequest(this.workflow.id);
      this.$router.push({ name: "workflows" });
      this.loadingDelete = false;
    },
    changeEntity(event) {
      this.workflow.action = "";
      this.workflow.execute_when = "";
      this.$store.commit("setCreateWorkflowEntity", event);

      if (["deal", "task"].includes(event)) {
        this.hideRelatedTo = false;
      } else {
        this.relatedTo = "others";
        this.hideRelatedTo = true;
      }
    },
    closeWorkflow() {
      this.$store.commit("resetWorkflowConditions");
      this.$router.push({
        name: "workflows"
      });
    },
    addCondition() {
      const fieldObject = {
        id: this.workflowConditions.length + 1,
        filterField: "",
        operator: "=",
        valueField: {
          value: "",
          type: "text",
          mask: "",
          items: []
        }
      };

      this.$store.commit("addWorkflowCondition", fieldObject);
    },
    removeWorkflowCondition(index) {
      this.$store.commit("removeWorkflowCondition", index);
    },
    changeConditionField(field) {
      field.valueField.type = "text";

      const customFieldRelated = this.customFields.find(
        e => e.name === field.filterField
      );

      if (customFieldRelated) {
        field.valueField.type = customFieldRelated.type;
        field.valueField.mask =
          field.valueField.type === "text" ? customFieldRelated.mask : "";

        if (customFieldRelated.values?.length > 0) {
          field.operator = "=";
          field.valueField.items = customFieldRelated.values;
        }
      }

      if (this.isCheckbox(field.filterField)) {
        field.operator = "=";
        field.valueField.type = "global-select";
        field.valueField.items = [
          {
            text: this.$t("true"),
            value: "true"
          },
          {
            text: this.$t("false"),
            value: "false"
          }
        ];
      }

      if (
        [
          "created_at",
          "start",
          "end",
          "updated_at",
          "birthdate",
          "closed_at",
          "paid_at",
          "due_date"
        ].includes(field.filterField)
      ) {
        field.valueField.type = "date";

        if (field.operator === "like") {
          field.operator = ">";
        }
      }

      if (["document"].includes(field.filterField)) {
        if (this.component === "company") {
          field.valueField.mask = "##.###.###/####-##";
        } else {
          field.valueField.mask = this.$root.$t("cpf-mask");
        }
      }

      if (["phone1"].includes(field.filterField)) {
        field.valueField.mask = this.$root.$t("phone-mask");
      }

      if (["task_type_id"].includes(field.filterField)) {
        field.operator = "=";
        field.valueField.type = "global-select";
        field.valueField.items = this.taskTypesList;
      }

      if (["user_id", "responsible_user_id"].includes(field.filterField)) {
        field.operator = "=";
        field.valueField.type = "users";
      }

      if (["product_id"].includes(field.filterField)) {
        field.operator = "=";
        field.valueField.type = "products";
      }

      if (["contact_id"].includes(field.filterField)) {
        field.operator = "=";
        field.valueField.type = "contacts";
      }

      if (["company_id"].includes(field.filterField)) {
        field.operator = "=";
        field.valueField.type = "companies";
      }

      if (["pipeline_stage_id"].includes(field.filterField)) {
        field.operator = "=";
        field.valueField.items = this.allStagesWithPipelinesList;
      }

      if (["pipeline_id"].includes(field.filterField)) {
        field.operator = "=";
        field.valueField.items = this.pipelinesList;
      }

      if (["pipeline_id", "pipeline_stage_id"].includes(field.filterField)) {
        field.operator = "=";
        field.valueField.type = "global-select";
      }

      return field;
    },
    async workflowAction() {
      this.btnLoading = true;

      const validated = this.$refs.form.validate();

      if (validated) {
        const payload = { ...this.workflow };

        payload.conditions = this.workflowConditions
          .filter(e => e.filterField?.trim().length > 0)
          .map(field => {
            const condition = {
              field: field.filterField,
              operator: field.operator,
              value: field.valueField.value
            };

            if (this.isCheckbox(condition.field)) {
              condition.value = condition.value === "true";
            }

            return condition;
          });

        payload.settings = { to: "" };

        if (payload.action === "send_email") {
          payload.settings.from = this.workflow.settings.from;
          payload.settings.to = this.receiverEmails;
          payload.settings.subject = this.workflow.settings.subject;
          payload.settings.message = this.workflow.settings.message;
        }

        if (payload.action === "send_confirmation_email") {
          payload.settings.from = this.workflow.settings.from;
          payload.settings.to = this.receiverEmails;
          payload.settings.subject = this.workflow.settings.subject;
          payload.settings.message = this.workflow.settings.message;
          payload.settings.approved_pipeline_id = this.selectedPipelineId;
          payload.settings.approved_stage_id = this.selectedStageId;
          payload.settings.declined_pipeline_id = this.selectedDeclinedPipelineId;
          payload.settings.declined_stage_id = this.selectedDeclinedStageId;
        }

        if (payload.action === "send_sms") {
          payload.settings.to = [this.workflow.settings.to];
          payload.settings.message = this.workflow.settings.message;
        }

        if (payload.action === "send_whatsapp") {
          payload.settings.to = [this.workflow.settings.to];
          payload.settings.token = this.workflow.settings.token;
          payload.settings.message = this.workflow.settings.message;
        }

        if (payload.action === "send_discord") {
          payload.settings.webhook = this.workflow.settings.webhook;
          payload.settings.message = this.workflow.settings.message;
        }

        if (payload.action === "send_slack") {
          payload.settings.token = this.workflow.settings.token;
          payload.settings.channel_id = this.workflow.settings.channel_id;
          payload.settings.message = this.workflow.settings.message;
        }

        if (payload.action === "calculate_field_value") {
          payload.settings.field = this.workflow.settings.field;
          payload.settings.formula = this.workflow.settings.formula;
        }

        if (payload.action === "change_field_value") {
          payload.settings.field = this.workflow.settings.field;
          payload.settings.new_value = this.workflow.settings.new_value;
        }

        if (this.relatedTo != "others") {
          payload.settings.to = this.relatedTo;
        }

        if (payload.action === "add_tag") {
          payload.settings.tag = this.workflow.settings.tag;
          delete payload.settings.to;
        }

        if (payload.action === "remove_tag") {
          payload.settings.tag = this.workflow.settings.tag;
          delete payload.settings.to;
        }

        if (payload.action === "add_task") {
          payload.settings.task_type_id = this.workflow.settings.task_type_id;
          payload.settings.timeout = this.workflow.settings.timeout;
          payload.settings.note = this.workflow.settings.note;
          payload.settings.responsible_user_id = this.workflow.settings.responsible_user_id;
          delete payload.settings.to;
        }

        if (payload.action === "webhook") {
          payload.settings.method = this.workflow.settings.method;
          payload.settings.endpoint = this.workflow.settings.endpoint;
          payload.settings.email = this.workflow.settings.email;
          payload.settings.headers = this.workflow.settings.headers;
          payload.settings.body = this.workflow.settings.body;
          delete payload.settings.to;
        }

        if (payload.action === "add_bill") {
          payload.settings.description = this.workflow.settings.description;
          payload.settings.type = this.workflow.settings.type;
          payload.settings.method = this.workflow.settings.method;
          payload.settings.total = this.workflow.settings.total;
          payload.settings.status = this.workflow.settings.status;
          payload.settings.timeout = this.workflow.settings.timeout;
          delete payload.settings.to;
        }

        if (payload.action === "add_note") {
          payload.settings.note = this.workflow.settings.note;
          delete payload.settings.to;
        }

        if (payload.action === "send_notification") {
          payload.settings.message = this.workflow.settings.message;
          if (this.notificationTo === "other") {
            payload.settings.to = this.workflow.settings.to;
          } else {
            payload.settings.to = this.notificationTo;
          }
        }

        if (this.senderIsDealResponsible) {
          payload.settings.from = "responsible";
        }

        if (payload.settings?.to?.length <= 0) {
          delete payload.settings.to;
        }

        if (this.workflowExist) {
          const workflowEdited = await this.editWorkflowRequest(payload);

          if (workflowEdited) {
            this.closeWorkflow();
          }
        } else {
          const workflowCreated = await this.createWorkflowRequest(payload);

          if (workflowCreated) {
            this.closeWorkflow();
          }
        }
      }

      this.btnLoading = false;
    },
    async doRequests() {
      await this.pipelinesRequest();
      await this.customFieldsRequest();
      await this.tasksRequest();

      if (this.workflowExist) {
        await this.workflowRequest(this.$route.params.id);
        this.workflow = this.$store.getters.getWorkflow;
      }

      this.loading = false;
    }
  },
  async mounted() {
    await this.doRequests();

    if (this.groups.length <= 0) {
      await this.workflowGroupsRequest();
    }

    if (this.workflowExist) {
      if (this.workflow.settings.from === "responsible") {
        this.senderIsDealResponsible = true;
        this.workflow.settings.from = "";
      }

      if (!this.workflow.settings.to) {
        this.workflow.settings.to = [];
      }

      if (!Array.isArray(this.workflow.settings.to)) {
        this.relatedTo = this.workflow.settings.to;
      } else {
        this.relatedTo = "others";

        if (this.workflow.action != "send_sms") {
          this.receiverEmails = this.workflow.settings.to;
        }

        this.hideRelatedTo = true;
      }

      if (["deal", "task"].includes(this.workflow.entity)) {
        this.hideRelatedTo = false;
      } else {
        this.relatedTo = "others";
        this.hideRelatedTo = true;
      }

      if (["send_notification"].includes(this.workflow.action)) {
        if (isNaN(this.workflow.settings.to)) {
          this.notificationTo = this.workflow.settings.to;
          this.workflow.settings.to = "";
        } else {
          this.notificationTo = "other";
        }
      }

      if (this.workflow?.conditions?.length > 0) {
        let items = [];

        this.workflow.conditions.forEach((condition, i) => {
          let field = {};

          if (Array.isArray(condition)) {
            field = {
              id: i + 9999,
              filterField: condition[0],
              operator: condition[1],
              valueField: {
                value: condition[2],
                type: "text",
                mask: "",
                items: []
              }
            };

            if (field.valueField.value.toString().includes("cf_")) {
              const customFieldName = field.valueField.value.split("%")[1];
              const customFieldValue = field.valueField.value.split("%")[2];

              field.filterField = customFieldName;
              field.valueField.value = customFieldValue;
            }

            if (field.valueField.value.toString().includes("%")) {
              field.valueField.value = field.valueField.value.substring(
                1,
                field.valueField.value.length - 1
              );
            }

            if (!isNaN(field.valueField.value)) {
              field.valueField.value = Number(field.valueField.value);
            }
          } else {
            field = {
              id: i + 9999,
              filterField: condition.field,
              operator: condition.operator,
              valueField: {
                value: condition.value,
                type: "text",
                mask: "",
                items: []
              }
            };
          }

          items.push(this.changeConditionField(field));
        });

        this.$store.commit("setWorkflowConditions", items);
      }

      if (this.pipelines?.length > 0) {
        if (
          this.pipelines.some(
            e => e.id === this.workflow.settings.approved_pipeline_id
          )
        ) {
          this.selectedPipelineId = this.workflow.settings.approved_pipeline_id;
        } else {
          this.selectedPipelineId = this.pipelines[0]?.id;
        }

        if (
          this.pipelines.some(
            e => e.id === this.workflow.settings.declined_pipeline_id
          )
        ) {
          this.selectedDeclinedPipelineId = this.workflow.settings.declined_pipeline_id;
        } else {
          this.selectedDeclinedPipelineId = this.pipelines[0]?.id;
        }

        const pipeline = this.pipelines.find(
          e => e.id === this.selectedPipelineId
        );

        if (
          pipeline?.stages.some(
            e => e.id === this.workflow.settings.approved_stage_id
          )
        ) {
          this.selectedStageId = this.workflow.settings.approved_stage_id;
        } else {
          this.selectedStageId = pipeline?.stages[0].id;
        }

        const declinedPipeline = this.pipelines.find(
          e => e.id === this.selectedDeclinedPipelineId
        );

        if (
          declinedPipeline?.stages.some(
            e => e.id === this.workflow.settings.declined_stage_id
          )
        ) {
          this.selectedDeclinedStageId = this.workflow.settings.declined_stage_id;
        } else {
          this.selectedDeclinedStageId = pipeline?.stages[0].id;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#edit-workflow {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 69px calc(100vh - 69px);
  position: relative;
}
</style>
