<i18n>
{
  "en": {
    "title": "Automations",
    "entity-1": "Cards",
    "entity-2": "Contacts",
    "entity-3": "Companies",
    "entity-4": "Users",
    "entity-5": "Tasks",
    "entity-6": "Products",
    "entity-7": "Bills",
    "create": "New automation",
    "not-create": "We didn't find any",
    "workflow": "automation",
    "click-button": "Click on the button",
    "new-workflow": "+ New automation",
    "to-create": "to create one",
    "duplicate": "Duplicate",
    "on-off": "Enable/Disable",
    "search": "Search for name of automation",
    "all": "All",
    "ungrouped": "Ungrouped",
    "groups": "Groups"
  },
  "pt-BR": {
    "title": "Automações",
    "entity-1": "Cards",
    "entity-2": "Contatos",
    "entity-3": "Empresas",
    "entity-4": "Usuários",
    "entity-5": "Tarefas",
    "entity-6": "Produtos",
    "entity-7": "Financeiro",
    "create": "Nova automação",
    "not-create": "Não encontramos nenhuma",
    "workflow": "automação",
    "click-button": "Clique no botão",
    "new-workflow": "+ Nova Automação",
    "to-create": "para criar",
    "duplicate": "Duplicar",
    "on-off": "Ativar/Desativar",
    "search": "Pesquisar pelo nome da automação",
    "all": "Tudo",
    "ungrouped": "Desagrupados",
    "groups": "Grupos"
  }
}
</i18n>

<template>
  <div id="workflows">
    <nav-top :title="$t('title')">
      <template #top-content>
        <we-input type="search" v-model="search" :inputLabel="$t('search')" />
        <we-button
          class="success"
          :text="$t('create')"
          icon="plus"
          @click="$router.push({ name: 'createWorkflow' })"
        />
      </template>
      <template #menu>
        <we-nav-x
          v-if="navigationItems && navigationItems.length > 0"
          :items="navigationItems"
          :activeIndex="currentNavIndex"
          @select="changeEntity"
        />
      </template>
    </nav-top>
    <div class="board">
      <div class="workflows-grid">
        <div class="entity-list">
          <router-view />
          <div
            class="item"
            v-for="group in generalGroups"
            :key="group"
            :class="{ active: group === selectedGroup }"
            @click="selectedGroup = group"
          >
            {{ getGroup(group) }}
          </div>
          <div
            class="list-name"
            v-if="workflowGroups && workflowGroups.length > 0"
          >
            {{ $t("groups") }}
          </div>
          <div
            class="item"
            v-for="group in workflowGroups"
            :key="group"
            :class="{ active: group === selectedGroup }"
            @click="selectedGroup = group"
          >
            {{ group }}
          </div>
        </div>
        <div class="workflow-selected">
          <div class="workflows-list">
            <we-not-found v-if="searchedWorkflows.length === 0">
              <template #title>
                {{ $t("not-create") }} <b>{{ $t("workflow") }}</b>
              </template>
              <template #description>
                {{ $t("click-button") }}
                <b class="green--text">{{ $t("new-workflow") }}</b>
                {{ $t("to-create") }}
              </template>
            </we-not-found>

            <container
              class="list-group"
              :animation-duration="200"
              @drop="e => moveWorkflow(e)"
              :get-child-payload="getCardPayload()"
              drag-class="dragged"
              :scrollSensitivity="200"
            >
              <draggable
                v-for="workflow in searchedWorkflows"
                :key="workflow.id"
              >
                <v-card
                  class="item"
                  :class="{ active: workflow.is_active }"
                  @contextmenu="showOptions({ event: $event, workflow })"
                  @click="
                    $router.push({
                      name: 'editWorkflow',
                      params: { id: workflow.id }
                    })
                  "
                >
                  <div class="informations">
                    <we-text-tooltip
                      :text="workflow.name"
                      lines="1"
                      :delay="1000"
                      class="name"
                    />
                    <we-text-tooltip
                      class="description"
                      :text="workflow.description"
                      lines="1"
                      :delay="1000"
                    />
                  </div>
                </v-card>
              </draggable>
            </container>
            <we-nested-menu
              name="New Menu"
              v-model="showMenu"
              :position-x="x"
              :position-y="y"
              :absolute="true"
              :offset-x="true"
              :menu-items="workflowActionsMenu"
              @we-nested-menu-click="onMenuItemClick"
            />
          </div>
        </div>
      </div>
      <we-loading-overflow :loading="loading" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Container, Draggable } from "vue-smooth-dnd";
export default {
  components: {
    Container,
    Draggable
  },
  data() {
    return {
      selectedGroup: "all",
      search: "",
      loading: true,
      showMenu: false,
      selectedWorkflow: {},
      x: 0,
      y: 0,
      currentNavIndex: 0
    };
  },
  computed: {
    generalGroups() {
      return ["all", "ungrouped"].filter(e => {
        if (this.workflowGroups.length <= 0 && e === "ungrouped") return false;

        return true;
      });
    },
    workflowGroups() {
      return [...this.$store?.getters?.getWorkflowGroups];
    },
    navigationItems() {
      return [
        {
          id: 0,
          name: this.$t("entity-1"),
          icon: "note-sticky",
          entity: "deal"
        },
        {
          id: 2,
          name: this.$t("entity-2"),
          icon: "users",
          entity: "contact"
        },
        {
          id: 3,
          name: this.$t("entity-3"),
          icon: "building",
          entity: "company"
        },
        {
          id: 4,
          name: this.$t("entity-4"),
          icon: "user-tie",
          entity: "user"
        },
        {
          id: 5,
          name: this.$t("entity-5"),
          icon: "tasks",
          entity: "task"
        },
        {
          id: 6,
          name: this.$t("entity-6"),
          icon: "cubes",
          entity: "product"
        },
        {
          id: 7,
          name: this.$t("entity-7"),
          icon: "file-invoice-dollar",
          entity: "bill"
        }
      ];
    },
    workflowActionsMenu() {
      return [
        {
          name: this.$t("duplicate"),
          icon: ["far", "copy"],
          action: () => {
            this.duplicateWorkflow();
          }
        },
        {
          name: this.$t("on-off"),
          icon: "toggle-off",
          action: () => {
            this.turnWorkflowOnOrOff();
          }
        }
      ];
    },
    entity() {
      return this.$store.getters.getCreateWorkflowEntity;
    },
    workflows() {
      return this.$store.getters.getWorkflows;
    },
    filteredWorkflows() {
      return this.workflows.filter(e => {
        if (e.entity != this.entity) return false;

        if (this.selectedGroup === "all") return true;

        if (this.selectedGroup === "ungrouped") {
          if (!e.group) {
            return true;
          }
        }

        if (this.selectedGroup === e.group) {
          return true;
        }

        return false;
      });
    },
    searchedWorkflows() {
      return this.filteredWorkflows.filter(e => {
        if (this.search.length <= 0) return true;

        if (e.name.toLowerCase().includes(this.search.toLowerCase())) {
          return true;
        }

        return false;
      });
    }
  },
  methods: {
    ...mapActions([
      "workflowsRequest",
      "workflowGroupsRequest",
      "editWorkflowRequest",
      "createWorkflowRequest"
    ]),
    getGroup(group) {
      if (group === "all") return this.$t("all");

      if (group === "ungrouped") return this.$t("ungrouped");

      return "";
    },
    getCardPayload() {
      return index => {
        return this.searchedWorkflows[index];
      };
    },
    async moveWorkflow(evt) {
      let workflow = evt.payload;
      this.searchedWorkflows.splice(evt.removedIndex, 1);
      this.searchedWorkflows.splice(evt.addedIndex, 0, evt.payload);
      workflow.indexes = this.searchedWorkflows.map(e => e.id);

      await this.editWorkflowRequest(workflow);
      this.doRequests();
    },
    showOptions({ event, workflow }) {
      this.selectedWorkflow = { ...workflow };

      event.preventDefault();
      this.showMenu = false;
      this.x = event.clientX;
      this.y = event.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    onMenuItemClick(item) {
      if (item.action) {
        item.action();
      }
    },
    async turnWorkflowOnOrOff() {
      this.loading = true;
      const payload = { ...this.selectedWorkflow };
      payload.is_active = !payload.is_active;
      await this.editWorkflowRequest(payload);
      this.doRequests();
    },
    async duplicateWorkflow() {
      this.loading = true;
      await this.createWorkflowRequest(this.selectedWorkflow);
      this.doRequests();
    },
    async doRequests() {
      this.loading = true;
      const payload = {
        params: {
          entity: this.entity
        }
      };
      await this.workflowsRequest(payload);
      await this.workflowGroupsRequest(payload);
      this.loading = false;
    },
    changeEntity(event) {
      this.currentNavIndex = event;
      this.selectedGroup = "all";
      this.search = "";
      this.$store.commit(
        "setCreateWorkflowEntity",
        this.navigationItems[event].entity
      );
      this.doRequests();
    },
    setWorkflowEntity() {
      const entity = this.$store.getters.getCreateWorkflowEntity;
      const index = this.navigationItems.findIndex(e => e.entity === entity);
      this.currentNavIndex = index;
    }
  },
  mounted() {
    this.doRequests();
    this.$store.commit("resetWorkflowConditions");
    this.setWorkflowEntity();
  },
  beforeRouteUpdate(to, from, next) {
    if (from.name.includes("create") || from.name.includes("edit")) {
      this.doRequests();
    }
    next();
  }
};
</script>

<style lang="scss">
#workflows {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  position: relative;

  .board {
    background: var(--background-2);
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    flex: 1;
    min-height: 0;
  }

  .workflows-grid {
    min-height: 500px;
    max-width: 800px;
    height: fit-content;
    margin: 0 auto;
    padding: 69px 50px;
    display: grid;
    grid-template-columns: 227px $column-size;
    gap: 18px;

    .entity-list {
      border-right: 1px solid var(--line);
      padding-right: 18px;

      .list-name {
        font-size: 14px;
        font-weight: $medium;
        text-transform: uppercase;
        color: var(--text-3);
        padding-left: 18px;
        padding-top: 16px;
        margin-top: 22px;
        border-top: 1px solid var(--line);
        margin-bottom: 8px;
        letter-spacing: 1px;
      }

      .item {
        height: fit-content;
        display: flex;
        align-items: center;
        padding: 9.5px 18px;
        font-size: 14px;
        font-weight: $medium;
        color: var(--text-1);
        background: var(--action);
        border-radius: 7px;
        cursor: pointer;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 60px;
        }

        &:hover {
          background: var(--action-hover);
        }

        &.active {
          color: var(--primary);
          background: var(--primary-bg);
        }
      }
    }

    .workflow-selected {
      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .name {
          font-size: 24px;
          font-weight: $regular;
          color: var(--text-1);
        }

        .actions {
          display: flex;
          align-items: center;

          button {
            margin-left: 4px;
          }
        }
      }

      .workflows-list {
        position: relative;
        min-height: 320px;
        position: relative;

        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 12px;
          padding-right: 4px;
          margin-bottom: 12px;
          border-radius: 10px;
          background: var(--background-1-hover);
          border: 1px solid var(--line);
          box-shadow: none;
          cursor: grab;

          &.active {
            background: var(--background-1);
          }

          &:hover {
            border-color: var(--primary);
          }

          span {
            text-transform: capitalize;
          }

          .informations {
            width: 100%;
            .name {
              font-size: 16px;
              font-weight: $medium;
              color: var(--text-1);
            }

            .description {
              font-size: 14px;
              color: var(--text-2);
            }
          }
        }
      }
    }
  }
}
</style>
